import React from "react"
import CommunityLayout from "../../components/layouts/communityLayout"
import ImageCard from "../../components/cards/imageCard.js"

import Bakery from "../../images/bakery.jpg"
import GaFuritcake from "../../images/gafruitcake.jpg"
import PFA from "../../images/pfa.jpg"
import Hames from "../../images/hames.jpg"
import House from "../../images/house.jpg"
import Depot from "../../images/depot.jpg"
import Pic from "../../images/courthouse.jpg"
import CommunityCenter from "../../images/community-center2.jpg"

const AttractionsPage = props => (
  <CommunityLayout pageTitle="Attractions">
    <div>
      <ImageCard src={Bakery} alt="">
        <h2>The Claxton Bakery, Inc.</h2>
        <p>
          203 W Main Street
          <br />
          Claxton, GA 30417
          <br />
          (912) 739-3441
          <br />
          1-800-841-4211
          <br />
          <a href="https://www.claxtonfruitcake.com/" target="_blank">
            www.claxtonfruitcake.com
          </a>
        </p>
        <p>
          A blend of nature’s finest fruits and nuts, Claxton Fruitcake has
          enjoyed a reputation for quality and value since 1910. Each year,
          millions of pounds of Claxton Fruitcake are produced and shipped
          worldwide.
        </p>
      </ImageCard>

      <ImageCard src={GaFuritcake} alt="">
        <h2>Georgia Fruitcake Company</h2>
        <p>
          5 S Duval St
          <br />
          Claxton, GA 30417
          <br />
          (912) 739-2683
          <br />
          <a href="http://www.georgiafruitcakecompany.com/" target="_blank">
            www.georgiafruitcakecompany.com
          </a>
        </p>
        <p>
          Highly skilled bakers work from recipes handed down through four
          generations. No one other than a direct descendant of the founder has
          ever mixed a batch of their fruitcake. The bakery also operates a
          coffee shop which is popular with locals and tourists alike.{" "}
        </p>
      </ImageCard>

      <ImageCard src={PFA} alt="">
        <h2>Georgia DNR Evans County Public Fishing Area</h2>
        <p>
          4757 Area Line Road
          <br />
          Claxton, GA 30417
          <br />
          Just off Highway 280 East, 8.5 miles from Claxton
          <br />
          (912) 727-2112
          <br />
          <a
            href="https://georgiawildlife.com/evans-county-pfa"
            target="_blank"
          >
            www.georgiawildlife.com
          </a>
        </p>
        <p>
          Located on a gently rolling 372- acre tract of land with three lakes
          that vary in size from 8 to 24 acres. Open year round from sunrise to
          sunset. Facilities include concrete boat ramp, fishing piers,
          boardwalk with disabilities access, covered picnic areas, primitive
          camping sites, public restrooms, an archery range, and nature trail.
          Coming Summer of 2019 – 14 Premium RV campsites, comfort station with
          showers, and Conference Center!{" "}
        </p>
      </ImageCard>

      <ImageCard src={Hames} alt="">
        <h2>Hames Hall</h2>
        <p>
          13350 Highway 129 <br /> Claxton, GA 30417
          <br />
          (912) 536-6630
          <br />
          <a href="https://www.hameshall.com/" target="_blank">
            www.hameshall.com
          </a>
        </p>
        <p>
          Hames Hall, a beautiful Greek revival-style mansion for weddings and
          special events.{" "}
        </p>
      </ImageCard>

      <ImageCard src={House} alt="">
        <h2>Historic DeLoach House</h2>
        <p>
          The original owner of the G.W. DeLoach house, helped settle the town
          of Hagan and was instrumental in achieving the formation of Evans
          County. Constructed in the 1890’s the historic DeLoach House is now
          owned by Caroline Blocker and is open to the public for meetings and
          special events.
        </p>
      </ImageCard>

      <ImageCard src={Depot} alt="">
        <h2>Bellville Depot</h2>
        <p>
          Like our other towns in Evans County, Bellville sprang up in 1890 with
          the coming of the railroad. The railroad depot, constructed soon after
          the tracks were laid, served as the towns center of commerce and
          social activity. The original depot is a historic landmark and is open
          to the public for meetings and special events.{" "}
        </p>
      </ImageCard>

      <ImageCard src={Pic} alt="">
        <h2>Historic Evans County Courthouse</h2>
        <p>
          Georgia has more counties than any other state, except Texas, a
          circumstance that has given the state a wealth of historic
          courthouses. All are listed on the National Register of Historic
          Places.{" "}
        </p>
        <p>
          JJ Baldwin designed the Evans County Courthouse in a Neoclassical
          style. Constructed in 1922-23, at a cost of $60,000, the Courthouse
          features a lighted clock in the dome of the cupola, along with the two
          story, four-columned portico.{" "}
        </p>
      </ImageCard>
      <ImageCard src={CommunityCenter} alt="">
        <h2>African American Archives</h2>
        <p>
        The Evans County African American Archives is located at the Evans County 
        Community Center, 720 North Church Street, Claxton, formerly home of the Evans 
        County High School, an Equalization School established in the 1950’s.  Exhibits 
        include artifacts from the school, including trophies, yearbooks, news articles 
        and photographs of school events such as proms, parades and sports.   The archives 
        also include displays of community and church programs, family trees, businesses, 
        notable community leaders and a variety of family produced project boards and exhibits 
        documenting the impact of early pioneers of Evans County and family genealogy.  
        <a href="mailto:evanscommunitycenter1954@gmail.com">evanscommunitycenter1954@gmail.com</a> {" "}
        </p>
      </ImageCard>
    </div>
  </CommunityLayout>
)

export default AttractionsPage
